<template>
  <div class="auth">
    <helmet>
      <title>{{$t('title.login')}}</title>
    </helmet>
    <message v-if="unauthorized">
      <template slot="title">{{$i18n.t('login.message.title')}}</template>
      <template slot="content">{{$i18n.t('login.message.content')}}</template>
      <a
        slot="buttons"
        class="button button_type_filled button_size_lg"
        href="tg://resolve?domain=ChannelQuizBot&start=login"
        target="_blank">
        {{$i18n.t('login.message.button')}}
      </a>
    </message>
    <message v-else-if="error">
      <template slot="title">{{$i18n.t('login.errorMessage.title')}}</template>
      <template slot="content">{{error}}</template>
      <a
        slot="buttons"
        class="button button_type_filled button_size_lg"
        href="tg://resolve?domain=ChannelQuizBot&start=login"
        target="_blank">
        {{$i18n.t('login.message.button')}}
      </a>
    </message>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'
  import Message from '@/components/Message.vue'

  import {
    apiCheckAuth,
    apiLogin
  } from '@/functions/api'

  export default {
    name: 'login',
    components: {
      Helmet,
      Message,
    },
    data() {
      return {
        error: false,

        unauthorized: false,
      }
    },
    methods: {
      async handleMounted() {
        const checkAuthResult = await apiCheckAuth()

        if (checkAuthResult.ok) {
          await this.$store.commit('auth/setUserId', checkAuthResult.result.user_id)

          return this.$router.push(this.$route.query.next || '/dashboard')
        }

        const token = this.$route.query.token
        if (!token) {
          this.unauthorized = true
          return
        }
        this.login(token)
      },

      login(token) {
        return apiLogin(token)
          .then((result) => {
            if (result.ok) {
              void this.$store.commit('auth/setUserId', result.result.user_id)

              return this.$router.push(this.$route.query.next || '/dashboard')
            }
            this.error = this.$i18n.t(`login.error. ${result.error.name}`)
          })
      },
    },
    async mounted() {
      return this.handleMounted()
    },
  }
</script>

<style scoped lang="stylus">
  .auth
    flex-grow 1
    display flex
    flex-direction column
    justify-content center
    align-items center

    .card
      padding 1.75rem
      border solid 1px #ddd
      border-radius .25rem

      display flex
      flex-direction column
      align-items center

      .title
        font-size 1.25rem
        padding-bottom 1.25rem

    .error
      font-size 3rem
      font-weight 700
      color red
</style>
